






import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import PageHeader from '@/admin/components/PageHeader.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: {
    PageHeader,
    Form
  },
  methods: { __ }
})
export default class EmployeeAdd extends Vue {
  @Prop() meta: any
  form: FormBase|null = null

  created() {
    this.form = (new FormBase())
      .setEndpoint(`company/${ this.company.slug }/projects/${ this.$router.currentRoute.params.uuid }/employees`)
      .setFields([
        (new SelectField())
          .setKey('employee_uuids')
          .isMultiple()
          .setTitle(__('company.views.projects.show.tabs.employees.dialog.form.employees'))
          .loadItems({ endpoint: `company/${ this.company.slug }/projects/${ this.$router.currentRoute.params.uuid }/employees?unassigned=true`, value: 'uuid', title: 'full_name',  })
      ])
      .setOnSuccess(this.onSuccess)
  }

  onSuccess() {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.projects.show.tabs.employees.dialog.success'),
    })

    this.$store.dispatch(GlobalActions.closeDialog)

    this.meta.onSuccess()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
