












import { Component, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import Employee from '@/shared/modules/employee/employee.model'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import EmployeeAdd from '@/company/components/views/projects/projects-show/EmployeeAdd.vue'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import http from '@/shared/http'

@Component({
  components: { DataTable, Can },
  methods: { __ }
})
export default class Employees extends Vue {
  permissions = permissions
  table: DataTableBase | null = null

  created() {
    const url: string = `company/${ this.company.slug }/projects/${ this.$router.currentRoute.params.uuid }/employees`

    this.table = new DataTableBase()
      .setModel(Employee)
      .setEndpoint(url)
      .setHeaders([
        new DataTableHeader()
          .setKey('fullName')
          .setEntryKey('full_name')
          .setText(__('company.views.projects.show.tabs.employees.table.columns.full-name')),
        new DataTableHeader()
          .setKey('email')
          .setText(__('company.views.projects.show.tabs.employees.table.columns.email')),
      ])
      .setActions([
        new DeleteAction()
          .setAction((item: Employee) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('company.views.projects.show.tabs.employees.table.actions.delete.title'),
              text: __('company.views.projects.show.tabs.employees.table.actions.delete.text', { name: item.fullName }),
              onYes: () => {
                http.delete(`${ url }/${ item.uuid }`)
                  .then(() => this.table && this.table.removeItem(item))
              }
            }
          }))
      ])
  }

  openEmployeeAdd() {
    const table: any = this.$refs.table

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EmployeeAdd,
      persistent: true,
      meta: {
        onSuccess: () => table.refresh()
      }
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
